<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <h1>heading</h1>
      advadv
      <br /><br /><br /><br /><br />dcdcddc<br /><br /><br /><br />adadvad<br /><br /><br />advadvad<br /><br />advadvadv<br /><br /><br /><br />advavd<br /><br /><br />
      <br /><br /><br /><br /><br />dcdcddc<br /><br /><br /><br />adadvad<br /><br /><br />advadvad<br /><br />advadvadv<br /><br /><br /><br />advavd<br /><br /><br />
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
export default {
  components: {},
  mixins: [WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
